import Vue from 'vue'

Vue.filter('type', function (input) {
  if (input === 'LAY') {
    return 'KHAI'
  } else if (input === 'BACK') {
    return 'LAGAI'
  } else if (input === 'L') {
    return 'K'
  } else {
    return 'L'
  }
})

Vue.filter('currencyConverter', function (input, multiplyer) {
  if (!multiplyer) {
    return input
  }
  if (!isNaN(input)) {
    const currency = Math.floor(input * multiplyer)
    if (currency < 10000000) {
      return currency
    } else {
      return '1cr+'
    }
  } else {
    return 0
  }
})

Vue.filter('currency', function (value, number) {
  if (!value) {
    return ''
  }
  const val = (value / 1).toFixed(number).replace(',', '.')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})
Vue.filter('safeUrl', function ($sce) {
  return function (val) {
    return $sce.trustAsResourceUrl(val)
  }
})

Vue.mixin({
  methods: {
    googleCalendarLink: (title = '', matchLink = '', startTime) => {
      startTime = startTime
        ? startTime.toString().replace(/-|:|\.\d+/g, '')
        : null
      let url = 'https://www.google.com/calendar/event?action=TEMPLATE'
      url += '&text=' + encodeURIComponent(title)
      url +=
        '&details=' +
        encodeURIComponent(process.env.THEME_SHORT_TITLE + ' Game Reminder.')
      url += '&location=' + encodeURIComponent(matchLink)
      url += '&dates=' + encodeURIComponent(startTime + '/' + startTime)
      url += '&sprop=website:' + encodeURIComponent(matchLink)
      url += '&sprop=name:' + encodeURIComponent(process.env.THEME_SHORT_TITLE)

      return url
    }
  }
})

Vue.filter('numberSymbol', function (num) {
  if (!isNaN(num)) {
    // if (num >= 10000000) {
    //   return (num / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr'
    // } else if (num >= 100000) {
    //   return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L'
    // } else if (num >= 1000) {
    //   return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    // }
    return num
  }
})

Vue.filter(
  'currencyFormat',
  function (number, maximumFractionDigits = 0, locale = 'in') {
    number = parseFloat(number)
    const localeList = { in: 'en-IN', us: 'en-US' }
    let twoFractionObject = {}
    if (maximumFractionDigits === 2) {
      twoFractionObject = {
        style: 'currency',
        currency: 'INR'
      }
    }
    const selectedLocale = localeList[locale]
      ? localeList[locale]
      : localeList.in
    if (!isNaN(number) && number) {
      const finalFinal = number.toLocaleString(selectedLocale, {
        maximumFractionDigits,
        ...twoFractionObject
      })
      let final
      if (maximumFractionDigits === 2) {
        final = finalFinal.replace('₹', '').replace('.00', '')
      } else if (maximumFractionDigits === 3) {
        final = finalFinal.replace('₹', '').replace('.000', '')
      } else {
        final = finalFinal.replace('₹', '')
      }
      return final
    } else if (number === '1cr+') {
      return '1cr +'
    } else {
      return 0
    }
  }
)

Vue.filter(
  'currencyFormatNet',
  function (number, maximumFractionDigits = 0, locale = 'in') {
    number = parseFloat(number)
    const localeList = { in: 'en-IN', us: 'en-US' }
    let twoFractionObject = {}
    if (maximumFractionDigits === 2) {
      twoFractionObject = {
        style: 'currency',
        currency: 'INR'
      }
    }
    const selectedLocale = localeList[locale]
      ? localeList[locale]
      : localeList.in
    if (!isNaN(number) && number) {
      const finalFinal = number.toLocaleString(selectedLocale, {
        maximumFractionDigits,
        ...twoFractionObject
      })
      let final
      if (maximumFractionDigits === 2) {
        final = finalFinal.replace('₹', '').replace('.00', '')
      } else if (maximumFractionDigits === 3) {
        final = finalFinal.replace('₹', '').replace('.000', '')
      } else {
        final = finalFinal.replace('₹', '')
      }
      let result
      if (Object.is(Number(final.replaceAll(',', '')), -0)) {
        result = 0
      } else {
        result = final
      }
      return result
    } else if (number === '1cr+') {
      return '1cr +'
    } else {
      return 0
    }
  }
)

Vue.filter(
  'fancyOdds',
  function (input, market, event, marketId, placeBetCalcType, betType) {
    input = parseFloat(input)
    if (betType === 'fancy') {
      input = (input - 1) * 100
    }
    if (marketId && marketId?.includes('bookmaker')) {
      if (marketId && marketId?.includes('-')) {
        return input
      } else if (placeBetCalcType === 0) {
        input = (input / 100 + 1).toFixed(3)
      } else if (placeBetCalcType === 2) {
        input = input.toFixed(3)
      } else {
        input = input.toFixed(3)
      }
    }
    return input
  }
)

Vue.filter('arthemisOdds', function (input, betType) {
  const result = (input - 1) * 100
  return Number.isInteger(result) ? result.toString() : result.toFixed(2)
})

Vue.filter('toFloor', function (input) {
  if (input === 0) {
    return 0
  } else if (input === '' || input === undefined) {
    return ''
  } else {
    return Math.floor(input)
  }
})

Vue.filter('ceil', function (input) {
  if (input === 0) {
    return 0
  } else if (input === '' || input === undefined) {
    return ''
  } else {
    return Math.ceil(input)
  }
})

Vue.filter('optimizedImage', function (imgUrl, width) {
  const newWidth = window.innerWidth < 767 ? width / 2 : width
  const actualImgUrl = imgUrl?.split('/')
  const imgixQuery = `?auto=compress&lossless=false&fm=webp&q=80&fit=crop&w=${newWidth}`
  let finalUrl = ''
  if (actualImgUrl && actualImgUrl.length > 0 && actualImgUrl[3]) {
    const lastUrlArray = actualImgUrl[3].split('?')
    if (lastUrlArray && lastUrlArray.length > 0) {
      finalUrl = process.env.IMGIX_IMAGE_DOMAIN + '/' + lastUrlArray[0]
    } else {
      finalUrl = actualImgUrl[3]
    }
  } else {
    finalUrl = imgUrl
  }
  finalUrl = 'https://' + finalUrl + imgixQuery
  return finalUrl
})

Vue.filter('newOptimizedImage', function (imgUrl, width) {
  const newWidth = window.innerWidth < 767 ? width / 2 : width
  const actualImgUrl = imgUrl.split('/')
  const imgixQuery = `?auto=compress&lossless=false&fm=webp&q=80&fit=crop&w=${newWidth}`
  let finalUrl = ''
  if (actualImgUrl && actualImgUrl.length > 0 && actualImgUrl[3]) {
    const lastUrlArray = actualImgUrl[3].split('?')
    if (lastUrlArray && lastUrlArray.length > 0) {
      finalUrl = process.env.IMGIX_IMAGE_DOMAIN + '/' + lastUrlArray[0]
    } else {
      finalUrl = actualImgUrl[3]
    }
  } else {
    finalUrl = process.env.IMGIX_IMAGE_DOMAIN + '/' + imgUrl
  }
  finalUrl = 'https://' + finalUrl + imgixQuery
  return finalUrl
})
Vue.filter('newOptimizedImageStatic', function (imgUrl, folderName, width) {
  const newWidth = window.innerWidth < 767 ? width / 2 : width
  const imgixQuery = `?auto=compress&lossless=false&fm=webp&q=80&fit=crop&w=${newWidth}`
  let finalUrl = ''
  finalUrl = process.env.IMGIX_IMAGE_DOMAIN + '/' + folderName + '/' + imgUrl
  finalUrl = 'https://' + finalUrl + imgixQuery
  return finalUrl
})
Vue.mixin({
  methods: {
    ceilMethod: (input) => {
      if (input === 0) {
        return 0
      } else if (input === '' || input === undefined) {
        return ''
      } else {
        return Math.ceil(input)
      }
    }
  }
})
